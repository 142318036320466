
import axios from 'axios';
export default {
    
    async createOrders(params)  {
        return await axios.post(`orders/create` , params)
    },
    async createOrdersList(params)  {
        return await axios.post(`orders/create/list` , params)
    },
    async updateOrdersColumn(column , value , data)  {
        return await axios.put(`orders/update_list?${column}=${value}` , data)
    },
    async deleteOrdersList(list)  {
        return await axios.delete(`orders/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportOrders(column , value)  {
        return await axios.get(`orders/report?${column}=${value}`)
    },
    async reportByOrderId(column , value)  {
        return await axios.get(`orders/report_by_order_id?${column}=${value}`)
    },
    async getAllOrders()  {
        return await axios.get(`orders/all`)
    },
    async getOneOrders(order_id)  {
        return await axios.get(`orders/all/${order_id}`)
    },
    async getOrdersByColumn(column , value)  {
        return await axios.get(`orders/filter?column=${column}&value=${value}`)
    },
    async deleteOrders(order_id)  {
        return await axios.delete(`orders/delete/${order_id}`)
    },
    async updateOrders(order_id , params)  {
        return await axios.put(`orders/update/${order_id}` , params)
    }
}